import React from "react"
import FormPage from "../../components/FormPage"
import './form-styles.css';
import AskQuestionForm from "../../components/forms/AskQuestionForm";

interface Props {

}

const AskAQuestion = ({ }: Props) => {
	return <FormPage title="Concierge Application">
		<AskQuestionForm />
	</FormPage>
}

export default AskAQuestion
