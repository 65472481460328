import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Divider from "../components/Divider"
import Bio from "../components/bio"

interface Props {
	title: string;
	url?: string;
	height?: number;
}

const FormPage: React.SFC<Props> = ({ title, url, height, children }) => {
	return (
		<Layout>
			<SEO title={title} />
			{url && height && <iframe src={url} style={{ maxWidth: "95%" }} scrolling="no" width="640" height={height} frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>}
			{children}
			<Divider />
			<footer>
				<Bio />
			</footer>
		</Layout>
	)
}

export default FormPage
