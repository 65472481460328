import React from 'react';

interface AskQuestionFormProps {

}

function AskQuestionForm({ }: AskQuestionFormProps) {
	return <form className="form-labels-on-top" action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSfplvd1CYBCAQTii4YzOcbPuuJPmGlAPcoHufgu5s34BSr61A/formResponse?embedded=true" target="_blank" method="POST" id="mG61Hd">
		<div className="form-title-row">
			<h1>Ask Me A Question</h1>
		</div>
		<div style={{ textAlign: 'left' }}>
			<label className="required">* Required</label>
		</div>
		<div className="form-row">
			<label>
				<span>Full Name <span className="required">*</span></span>
				<input type="text" autoComplete="off" tabIndex={0} aria-label="Full Name" aria-describedby="i.desc.163141097 i.err.163141097" name="entry.1593786954" required dir="auto" data-initial-dir="auto" data-initial-value="" />
			</label>
		</div>
		<div className="form-row">
			<label>
				<span>Email Address <span className="required">*</span></span>
				<input type="text" autoComplete="off" tabIndex={0} aria-label="Email Address" aria-describedby="i.desc.2004185865 i.err.2004185865" name="entry.1644887552" required dir="auto" data-initial-dir="auto" data-initial-value="" />
			</label>
		</div>
		<div className="form-row">
			<label>
				<span>What is your question? <span className="required">*</span></span>
				<textarea data-rows="1" tabIndex={0} aria-label="What is your question?" required name="entry.944028685" dir="auto" data-initial-dir="auto" data-initial-value="" aria-describedby="i.desc.532063638 i.err.532063638"></textarea>
			</label>
		</div>
		<button type="submit">Ask</button>
	</form>
}

export default AskQuestionForm